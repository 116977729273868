/* You can add global styles to this file, and also import other style files */

@font-face {
    font-family: "LibreFranklin";
    src: local("LibreFranklin"), url(./assets/fonts/LibreFranklin-Regular.ttf) format("truetype");
}

html {
 background-color: #EDEDED;
 font-family: LibreFranklin sans-serif;
}


@mixin phone-only {
    @media (max-width: 599px) {
        @content
    }
}

@mixin tablet-portrait-up {
    @media (min-width: 600px) {
        @content;
    }
}

@mixin tablet-landscape-up {
    @media (min-width: 900px) {
        @content;
    }
}

@mixin tablet-desktop-up {
    @media (min-width: 1200px) {
        @content;
    }
}
